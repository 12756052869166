import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Col, Button, Container, Row, Navbar, Image, Modal } from 'react-bootstrap';
import Logo from '../assets/logo_transparente.png';
import { fetchCustomer, fetchCreateApplication } from '../actions/fetchs';
import { getCustomer } from '../selectors/customer';
import { getApplication } from '../selectors/application';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/BounceLoader'

var completo = true;
var descripcion = 'Sucursal';
var idorden = '';
var holder_descripcion = 'En que sucursal se entregara';

class Formulario extends Component {
	constructor(props) {
		super(props);
		this.state = {
			customer: [],
			application: [],
			folio_ra: 'problemas al registrar el folio intente de nuevo',
			show_modal:false,
			show_modal_alert:false,
			mensaje_error:'Problemas con el servidor contactar soporte',
			idorden: '',
			isActive:true
		};
	}
	componentDidMount = async (e) => {
		const { match: { params } } = this.props;

		idorden = params.idorden;
		
		await this.props.fetchCustomer(this.state.idorden).then((val) => {
			this.setState({
				idorden: params.idorden,
				customer: this.props.customer
			});
			switch (this.props.customer[0].success) {
				case undefined:
					if (this.props.customer[0].cliente.imei !== undefined) {
						this.setState({
							isActive: false,
							show_modal_alert: false
						});
					} else {
						this.setState({
							isActive: false,
							show_modal_alert: true,
							mensaje_error: 'Problemas al obtener informacion del servidor - Error(1)',
						});
					}
					
					break;
				case false:
					this.setState({
						isActive: false,
						show_modal_alert: true,
						mensaje_error: 'No se encuentro registro - Error(2)',
					});
					
					break;
				case true:
					if(this.props.customer[0].folio !== undefined){
						this.setState({
							isActive: false,
							show_modal_alert: true,
							mensaje_error: `Este equipo ya cuenta con folio de recuperación Folio: ${this.props.customer[0].folio}`,
						});
					}else{
						this.setState({
							isActive: false,
							show_modal_alert: false
						});
					}
					
					break;
			
				default:
					break;
			}

		});
		
		
	};
	getSelected = (e) => {
		console.log(this.props.customer);
		if (e === 'sucursal') {
			descripcion = 'Sucursal';
			holder_descripcion = 'En que sucursal se entregara';
		} else {
			descripcion = 'Agente';
			holder_descripcion = 'Nombre de agente que atendera';
		}
		this.setState({});
	};
	showModal = () => {
		this.setState({
			show_modal:true,
		});
	};
	validForm = (e) => {
		console.log('validar ' + e);
		if (e.trim() !== '') {
			completo = false;
		} else {
			completo = true;
		}
		this.setState({});
	};
	createApplication = (e) => {
		e.preventDefault();
		var datos_envio = this.state
			.customer[0].cliente; 
		
		datos_envio = { ...datos_envio, tipo_envio: e.target[0].value, descripcion_envio: e.target[1].value };
		
		this.props.fetchCreateApplication(datos_envio).then((val) => {
			this.setState({
				application: this.props.application,
				folio_ra:this.props.application.folios[0]
			});
			this.showModal()
		});
		console.log(this.props.application);
	};
	render() {
		
		return (
			<LoadingOverlay
  active={this.state.isActive}
  spinner={<ScaleLoader />}
  text='Cargando...'
  >
			<div className="form-section-s">
				
  

				<Navbar className="nav-s">
					<Navbar.Brand href="#home">
						<Image src={Logo} rounded height="100" />
					</Navbar.Brand>
					<Navbar.Toggle />
					{/* <Navbar.Collapse className="justify-content-end">
						<Navbar.Text>
							Signed in as: <a href="#login">Mark Otto</a>
						</Navbar.Text>
					</Navbar.Collapse> */}
				</Navbar>
				<Modal show={this.state.show_modal}  >
					<Modal.Header >
						<Modal.Title>Folio de recuperación</Modal.Title>
					</Modal.Header>
				<Modal.Body>Folio: {this.state.folio_ra}</Modal.Body>
				</Modal>
				<Modal show={this.state.show_modal_alert}  >
				<Modal.Body>{this.state.mensaje_error}</Modal.Body>
				</Modal>
				<Container
					fluid="md"
					className="container-s"
					
				>
					<Row>
						<Col>
							<Form onSubmit={this.createApplication}>
								<Form.Row>
									<Form.Group as={Col} controlId="tipo_recibo">
										<Form.Label className="label-name" >
											Tipo de recepción
										</Form.Label>
										<Form.Control
											as="select"
											name="tipo_entrega"
											onChange={(e) => this.getSelected(e.target.value)}
											defaultValue="1"
										>
											<option value="sucursal">En suscursal</option>
											<option value="agente">Agente va por equipo</option>
										</Form.Control>
									</Form.Group>
								</Form.Row>

								<Form.Group controlId="info_extra">
									<Form.Label className="label-name">
										{descripcion}
									</Form.Label>
									<Form.Control
										as="textarea"
										rows="3"
										name="descripcion_entrega"
										onChange={(e) => this.validForm(e.target.value)}
										placeholder={holder_descripcion}
									/>
								</Form.Group>

								<Button
									variant="primary"
									className="button-s"
									disabled={completo}
									type="submit"
								>
									Generar folio
								</Button>
							</Form>
						</Col>
					</Row>
				</Container>
			</div></LoadingOverlay>
		);
	}
}
Formulario.propTypes = {
	fetchCustomer: PropTypes.func.isRequired
};
Formulario.defaultProps = {
	customer: {},
	application: {},
	cantidad: 'Cargando...'
};
const mapDispatchToProps = (dispatch) => ({
	fetchCustomer: () => dispatch(fetchCustomer(idorden)),
	fetchCreateApplication: (e) => dispatch(fetchCreateApplication(e))
});
const mapStateToProps = (state) => ({
	customer: getCustomer(state),
	application: getApplication(state)
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Formulario));
