import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Formulario from './components/Formulario';
import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path='/:idorden' exact component={Formulario} />
          <Route path='/' exact component={Formulario} />
          <Route path='' exact component={Formulario} />
        </Switch>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
