export const apiGet = (url, obj) => () => {
	return fetch(`${url}${obj}`, {
        method: 'GET',
        headers:{
            'X-Requested-With': 'XMLHttpRequest',
            'Content-type': 'application/json'
          }
	}).then((v) => {
		return v.json();
	}).then((r) => {
		if (r===null||r.error) {
			return Promise.reject(r);
		}
		return r;
	});
};

export const apiPost = (url, obj) => () =>
	fetch(`${url}`, {
		method: 'POST',
		body: JSON.stringify({ datos: obj }),
		headers: new Headers({ 'Content-type': 'application/json',
		'X-Requested-With': 'XMLHttpRequest' })
	})
		.then((v) => v.json())
		.then((r) => {
			if (r.error) {
				return Promise.reject(r.validation);
			}
			return r;
		});
