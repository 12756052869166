import {FETCH_CUSTOMER,FETCH_APPLICATION} from '../constants'
import {createAction} from 'redux-actions'
import { apiGet,apiPost } from '../api'
import { urlCustomer,urlIntranet } from '../api/urls'


export const fetchCustomer = createAction(FETCH_CUSTOMER, 
    customer => apiGet(urlCustomer, customer)() );

export const fetchCreateApplication = createAction(FETCH_APPLICATION, 
    application => apiPost(urlIntranet, application)() );