import {handleActions} from 'redux-actions';
import {FETCH_APPLICATION} from './../constants'



export const application = handleActions({[FETCH_APPLICATION]: (state,action)=>{
    try {
        return action.payload;
    } catch (error) {
        return {'success':false,'msg':'problemas al generar folio'};
    }
    },},[]);

